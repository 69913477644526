<script>
import SwiperCore, { Thumbs, Navigation, Pagination } from "swiper";
import { mapActions, mapGetters } from 'vuex';
import "swiper/swiper-bundle.css";

SwiperCore.use([Thumbs, Navigation, Pagination]);

import Layout from "./layouts/main.vue";
// import appConfig from "../../../../app.config";

export default {
  data() {
    return {
      user: {},
      baseURl: null,
      title: "Profile",
      items: [
        {
          text: "Velzon",
          href: "/",
        },
        {
          text: "Profile",
          active: true,
        },
      ],
      thumbsSwiper: null,
    };
  },
  components: {
    Layout,
  },
  methods: {
    ...mapActions({
      setServices: "service/setServices",
      setIndustries: "industry/setIndustry",
      setSkills: "skills/setSkills",
      setPublicProfileData: "users/setPublicProfileData",
    }),
  },
  computed: {
    ...mapGetters({
      getSkills: "skills/getSkills",
      getIndustries: "industry/getIndustry",
      getServices: "service/getServices",
    }),
  },
  mounted() {
    this.setPublicProfileData(this.$route.params.id.split('~')[1])
      .then(({ data }) => {
        this.user = data.data.user
        // console.log(data.data);
      })

    this.baseURl = process.env.VUE_APP_API_URL;

    this.setServices()
    this.setIndustries()
    this.setSkills()

  }
};
</script>

<template>
  <Layout>
    <div class="profile-foreground position-relative mx-n4 mt-n4" v-if="user">
      <div class="profile-wid-bg">
        <img src="@/assets/images/profile-bg.jpg" alt="" class="profile-wid-img" />
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4">
      <div class="row g-4">
        <div class="col-auto">
          <div class="avatar-lg">
            <img :src="`${baseURl}/${user.passport_photo}`" v-if="user.passport_photo && user" alt="user-img"
              style="aspect-ratio: 1;" class="img-thumbnail rounded-circle" />
          </div>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1" v-if="user.name && user">{{ user.name }}</h3>
            <p class="text-white-75" v-if="user.qualification && user">{{ user.qualification }}</p>
            <div class="hstack text-white-50 gap-1">
              <div class="me-2 text-capitalize" v-if="user.state && user.country && user">
                <i class="
                    ri-map-pin-user-line
                    me-1
                    text-white-75
                    fs-16
                    align-bottom
                  "></i>{{ user.state }}, {{ user.country }}
              </div>
              <div class="text-capitalize">
                <i class="ri-building-line me-1 text-white-75 fs-16 align-bottom"></i>{{ user.city }}
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <!--end col-->
      </div>
      <!--end row-->
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div>
          <div class="d-flex">
            <!-- Nav tabs -->
            <ul class="
                nav nav-pills
                animation-nav
                profile-nav
                gap-2 gap-lg-3
                flex-grow-1
              " role="tablist">
              <li class="nav-item">
                <a class="nav-link fs-14 active" data-bs-toggle="tab" href="#overview-tab" role="tab">
                  <i class="ri-airplay-fill d-inline-block d-md-none"></i>
                  <span class="d-none d-md-inline-block">Overview</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- Tab panes -->
          <div class="tab-content pt-4 text-muted">
            <div class="tab-pane active" id="overview-tab" role="tabpanel">
              <div class="row">
                <div class="col-xxl-3">


                  <div class="card">
                    <div class="card-body">
                      <h5 class="card-title mb-3">Info</h5>
                      <div class="table-responsive">
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr v-if="user.name && user">
                              <th class="ps-0" scope="row">Full Name :</th>
                              <td class="text-muted text-capitalize">{{ user.name }}</td>
                            </tr>
                            <tr v-if="user.created_at && user">
                              <th class="ps-0" scope="row">Joining Date :</th>
                              <td class="text-muted">{{ user.created_at.split('T')[0] }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>
                  <!-- end card -->

                  <!-- end card -->

                  <div class="card" v-if="user.user_skills && getSkills" >
                    <div class="card-body">
                      <h5 class="card-title mb-4">Skills</h5>
                      <div class="d-flex flex-wrap gap-2 fs-16">
                        <a href="javascript:void(0);" class="badge badge-soft-primary" v-for="(skill , index) in getSkills.filter((curr , i) => curr.skills_id == user.user_skills[i]?.skill_id)" :key="index" >{{ skill.skill_name }}</a>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>

                  <div class="card" v-if="user.user_industries && getIndustries" >
                    <div class="card-body">
                      <h5 class="card-title mb-4">Industries</h5>
                      <div class="d-flex flex-wrap gap-2 fs-16">
                        <a href="javascript:void(0);" class="badge badge-soft-primary" v-for="(skill , index) in getIndustries.filter((curr , i) => curr.industries_id == user.user_industries[i]?.industry_id)" :key="index" >{{ skill.industries_name }}</a>
                      </div>
                    </div>
                    <!-- end card body -->
                  </div>

                </div>
                <!--end col-->
                <div class="col-xxl-9">
                  <div class="card" v-if="user.bio && user">
                    <div class="card-body">
                      <h5 class="card-title mb-3">About</h5>
                      <p>
                        {{ user.bio }}
                      </p>
                      <!--end row-->
                    </div>
                    <!--end card-body-->
                  </div>
                  <!-- end card -->

                  <!-- end card -->
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
            <!--end tab-pane-->
            <!--end tab-pane-->
          </div>
          <!--end tab-content-->
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </Layout>
</template>
